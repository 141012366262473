import { action } from "mobx";
import { Observer, observer } from "mobx-react";
import React from "react";
import { setTimeout } from "timers";
import { DialogSingleton } from "../../components/dialog/AppOverlay";
import { Icon, IconSources } from "../../components/Icon";
import { TextInput } from "../../components/inputs/TextInput";
import { CategoricalList } from "../../components/panels/CategoricalList";
import { Table } from "../../components/panels/Table";
import { BaseProps } from "../GeneralMachine";
import { Keywords } from "../Keywords";
import { SearchResultFood } from "../services/generated";
import { FdcFood } from "../services/ShelfLifeService";
import { Utils } from "../Utils";
import { HealthRisksMachine, Filter, FiltersEnum, SortBy, Sort } from "./HealthRisksMachine";
import { DecodeHintType, useZxing } from "react-zxing";
import { BarcodeFormat } from "@zxing/library";
import { LocalizationSingleton } from "../services/localization/LocalizationSingleton";
export interface BarcodeScannerProps {
  setResult: (code: string) => void;
}

export const BarcodeScanner = (props: BarcodeScannerProps) => {
  let formats: Map<DecodeHintType, any> =
    new Map([
      [DecodeHintType.TRY_HARDER, true]
    ]);
  formats.set(DecodeHintType.POSSIBLE_FORMATS, [BarcodeFormat.UPC_A]);
  const { ref } = useZxing({
    hints: formats, constraints: { video: { facingMode: "environment" } },
    onResult(result) {
      console.log(result.getText())
      props.setResult(result.getText());
      console.log(result.getBarcodeFormat());
      DialogSingleton.getManager().closeDialog("video")
    },
  });

  return (
    <>
      <div className="video-overlay"></div>
      <video ref={ref} playsInline autoPlay loop muted id="myVideo" />
    </>
  );
};

@observer
export class HealthRisks extends React.Component<BaseProps> {
  handleScan(code: string) {
    this.props.parentMachine.risksMx.searchFDC(true, code).then(() => {
      if (this.props.parentMachine.risksMx.lastSearch.length === 0) {
        this.props.parentMachine.risksMx.searchFDC(false, "00" + code)
      }
    });
  }

  handleError(err: string) {
    console.error(err)
  }

  renderSearchBar(): React.ReactNode {
    return <div className="search row" onKeyPress={async (event) => {
      if (event.key === "Enter") {
        this.props.parentMachine.risksMx.searchFDC(true);
        event.preventDefault();
        event.stopPropagation();
      }
    }}>
      <TextInput value={this.props.parentMachine.risksMx.search}
        list={HealthRisksMachine.searchDatalist}
        onChange={action((newVal) => this.props.parentMachine.risksMx.search = newVal)} />
      <datalist id={HealthRisksMachine.searchDatalist}>
        {this.props.parentMachine.risksMx.searchAutocomplete.filter((search) => search !== this.props.parentMachine.risksMx.search)
          .map((search) => <option key={search} value={search} />)}
      </datalist>
      <Icon name="barcode" source={IconSources.FONTAWESOME} onClick={() => this.renderBarCodeVideo()} tooltip={LocalizationSingleton.getService().words.BarcodeTooltip} />
      <Icon name="search" source={IconSources.FONTAWESOME} onClick={async () => {
        this.props.parentMachine.risksMx.searchFDC(true);
      }} tooltip={LocalizationSingleton.getService().words.SearchTooltip} />
      <Icon name="sliders" className="mobile-button" source={IconSources.FONTAWESOME}
        tooltip={LocalizationSingleton.getService().words.SearchPref}
        onClick={() => {
          this.props.parentMachine.risksMx.showingMenu = !this.props.parentMachine.risksMx.showingMenu;
        }} />
    </div>
  }

  preferencesContent(embedded: boolean = false): React.ReactElement {
    return <div><Observer>{() => <div className="preferences">
      <div className="section">
        <div className="label">{LocalizationSingleton.getService().words.SortBy} </div>
        <div>
          <div key="keyword" className="option" onClick={action((event) => {
            this.props.parentMachine.risksMx.sortBy = "lowercaseDescription.keyword";
            this.props.parentMachine.risksMx.sortOrder = "asc";
            embedded && this.props.parentMachine.risksMx.savePreferences();
          })}><input type="radio" value="lowercaseDescription.keyword" checked={this.props.parentMachine.risksMx.sortBy === "lowercaseDescription.keyword"}
            onChange={action((event) => {
              this.props.parentMachine.risksMx.sortBy = event.target.value as SortBy;
              this.props.parentMachine.risksMx.sortOrder = "asc";
              embedded && this.props.parentMachine.risksMx.savePreferences();
            })}
            name="sortBy" /> {LocalizationSingleton.getService().words.ProductName} </div>
          <div key="date" className="option" onClick={action(() => {
            this.props.parentMachine.risksMx.sortBy = "publishedDate";
            this.props.parentMachine.risksMx.sortOrder = "asc";
            embedded && this.props.parentMachine.risksMx.savePreferences();
          })}>
            <input type="radio" value="publishedDate" checked={this.props.parentMachine.risksMx.sortBy === "publishedDate"}
              onChange={action((event) => {
                this.props.parentMachine.risksMx.sortBy = event.target.value as SortBy;
                this.props.parentMachine.risksMx.sortOrder = "asc";
                embedded && this.props.parentMachine.risksMx.savePreferences();
              })}
              name="sortBy" /> {LocalizationSingleton.getService().words.PublishedDate} </div>
          <div key="fdcid" className="option" onClick={action(() => {
            this.props.parentMachine.risksMx.sortBy = "fdcId";
            this.props.parentMachine.risksMx.sortOrder = "asc";
            embedded && this.props.parentMachine.risksMx.savePreferences();
          })}>
            <input type="radio" value="fdcId" checked={this.props.parentMachine.risksMx.sortBy === "fdcId"}
              onChange={action((event) => {
                this.props.parentMachine.risksMx.sortBy = event.target.value as SortBy;
                this.props.parentMachine.risksMx.sortOrder = "asc";
                embedded && this.props.parentMachine.risksMx.savePreferences();
              })}
              name="sortBy" /> {LocalizationSingleton.getService().words.FDCId} </div>
          <div key="none" className="option" onClick={action(() => {
            this.props.parentMachine.risksMx.sortBy = undefined;
            this.props.parentMachine.risksMx.sortOrder = "asc";
            embedded && this.props.parentMachine.risksMx.savePreferences();
          })}>
            <input type="radio" value="undefined" checked={!this.props.parentMachine.risksMx.sortBy}
              onChange={action(() => {
                this.props.parentMachine.risksMx.sortBy = undefined;
                this.props.parentMachine.risksMx.sortOrder = undefined;
                embedded && this.props.parentMachine.risksMx.savePreferences();
              })}
              name="sortBy" /> {LocalizationSingleton.getService().words.None} </div>
        </div>
      </div>
      <div className="section">
        <div className="label">{LocalizationSingleton.getService().words.SortOrder} </div>
        <div>
          <select className="btn" value={this.props.parentMachine.risksMx.sortOrder} disabled={!this.props.parentMachine.risksMx.sortBy}
            onChange={action((event) => {
              this.props.parentMachine.risksMx.sortOrder = event.target.value as Sort;
              embedded && this.props.parentMachine.risksMx.savePreferences();
            })}>
            <option value="asc">{LocalizationSingleton.getService().words.Ascending}</option>
            <option value="desc">{LocalizationSingleton.getService().words.Descending}</option>
          </select>
        </div>
      </div>
      <div className="section">
        <div className="label">{LocalizationSingleton.getService().words.BrandOwner} </div>
        <TextInput value={this.props.parentMachine.risksMx.brandOwner}
          onChange={action((value: string) => {
            this.props.parentMachine.risksMx.brandOwner = value;
            embedded && this.props.parentMachine.risksMx.savePreferences();
          })} />
      </div>
    </div>}
    </Observer>
    </div>
  }

  renderBarCodeVideo(): void {
    navigator.mediaDevices.getUserMedia(
      // constraints
      {
        video: { facingMode: "environment" },
        audio: false
      }).then((value: MediaStream) => {
        let onClose = () => {
          value.getTracks().forEach(function (track) {
            track.stop();
          });
        }
        DialogSingleton.getManager().openDialog({
          key: "video", className: "video-dlg", hasDefaultFooter: true,
          title: "", onCancel: () => {
            onClose()
          },
          content: <BarcodeScanner setResult={(code: string) => {
            this.handleScan(code);
            code && onClose();
          }} />
        });
        let emitterVideo: HTMLVideoElement | null = document.querySelector('#myVideo')
        if (emitterVideo) {
          emitterVideo.srcObject = value
        }
      });
  }

  renderPreferencesDlg(): void {
    DialogSingleton.getManager().openDialog({
      key: "advanced", className: "preference-dlg", hasDefaultFooter: true,
      title: LocalizationSingleton.getService().words.SearchPref, onCancel: () => {
        this.props.parentMachine.risksMx.cancelPreferences();
      }, onOk: () => this.props.parentMachine.risksMx.savePreferences(),
      content: this.preferencesContent()
    })
  }

  renderFilters(): React.ReactNode {
    let addAllergen = () => {
      this.props.parentMachine.risksMx.personalToxicity.push(new Filter(this.props.parentMachine.risksMx.allergy, () => this.props.parentMachine.risksMx.allergy, true))
      this.props.parentMachine.risksMx.allergy = "";
      this.props.parentMachine.risksMx.updateAllergiesLocalStorage()
    }

    let categories = [{
      header: LocalizationSingleton.getService().words.Preferences, expanded: this.props.parentMachine.risksMx.preferencesExpanded,
      onExpandedChange: action(() => this.props.parentMachine.risksMx.preferencesExpanded = !this.props.parentMachine.risksMx.preferencesExpanded),
      items: [this.preferencesContent(true)]
    },
    {
      header: LocalizationSingleton.getService().words.Allergens, expanded: this.props.parentMachine.risksMx.allergensExpanded,
      onExpandedChange: action(() => this.props.parentMachine.risksMx.allergensExpanded = !this.props.parentMachine.risksMx.allergensExpanded),
      items: [<div>
        {this.props.parentMachine.risksMx.personalToxicity.map((item) => {
          return <div><input type="checkbox" checked={item.included} onChange={action(() => {
            item.included = !item.included;
            this.props.parentMachine.risksMx.updateAllergiesLocalStorage()
          })} />{item.id}</div>
        })}
        <div onKeyPress={action((e) => {
          if (e.key === "Enter") {
            addAllergen()
          }
        })}>
          <Icon source={IconSources.FONTAWESOME} name="plus"
            tooltip={LocalizationSingleton.getService().words.AddToAllergens}
            disabled={!this.props.parentMachine.risksMx.allergy}
            onClick={this.props.parentMachine.risksMx.allergy ? action(() => {
              addAllergen()
            }) : undefined} />
          <TextInput value={this.props.parentMachine.risksMx.allergy} onChange={action((newVal) => this.props.parentMachine.risksMx.allergy = newVal)} />
        </div>

      </div>]
    }];
    if (this.props.parentMachine.risksMx.lastSearch.length > 0) {
      categories = categories.concat([{
        header: LocalizationSingleton.getService().words.Filters, expanded: this.props.parentMachine.risksMx.filtersExpanded,
        onExpandedChange: action(() => this.props.parentMachine.risksMx.filtersExpanded = !this.props.parentMachine.risksMx.filtersExpanded),
        items: this.props.parentMachine.risksMx.filters.map((filter) => {
          return <div><input type="checkbox" checked={filter.included} onChange={action(() => {
            filter.included = !filter.included;
          })} />{filter.title}</div>
        })
      }]);
    }

    return <div className="filters">
      {this.renderSearchBar()}
      <div onClick={action((event) => {
        if (event.currentTarget.className.indexOf("mobile-background") !== -1) {
          this.props.parentMachine.risksMx.showingMenu = !this.props.parentMachine.risksMx.showingMenu;
          DialogSingleton.getManager().openDialog({
            key: "saved", hideHeader: true,
            content: <div className="alert success">{LocalizationSingleton.getService().words.SavedChanges}</div>, x: event.pageX, y: event.pageY
          })
          setTimeout(() => DialogSingleton.getManager().closeDialog("saved"), 2000)
        }
      })}
        className={"mobile-background " + (this.props.parentMachine.risksMx.showingMenu ? "showing" : "hidding")}>
        <CategoricalList categories={categories} />  </div>
    </div>;
  }

  capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  render() {
    let filters = this.props.parentMachine.risksMx.filters.filter((filter) => filter.included);

    return <div className="health-risks">
      <div className="alert warning">
        {LocalizationSingleton.getService().words.ToxicDisclaimer}
      </div>
      {LocalizationSingleton.getService().language === "es" && <div className="alert error">
        Alergénicos deben de ser especificados en Ingles. Los resultados de la búsqueda aparecerán en Ingles. Lo sentimos por las restricciones.
      </div>}
      <div className="results-wrapper">
        {this.renderFilters()}
        {this.props.parentMachine.risksMx.busy ? LocalizationSingleton.getService().words.Loading
          : this.props.parentMachine.risksMx.lastSearch.length > 0 ? <div>
            <Table columns={() => this.props.parentMachine.risksMx.columns}
              onTouchAndDragged={(item) => this.props.parentMachine.risksMx.addItemToInventory(item)}
              rows={(filters.length > 0 ? this.props.parentMachine.risksMx.lastSearch.filter((row: SearchResultFood) => filters.some((f) => Utils.getFoodStatus(row).indexOf(f.id as FiltersEnum) > -1))
                : this.props.parentMachine.risksMx.lastSearch)} id="results" title={LocalizationSingleton.getService().words.Results || ""}
              rowHeader={(item: FdcFood, withText?: boolean) => <div className="action-wrapper">
                <div className={this.props.parentMachine.risksMx.lastSearch.length === 1 ? "toxic-big-banner" : ""}>
                  {item.toxicIngredients && item.toxicIngredients.length > 0
                    ? <><div className="toxic-label">!
                    </div>
                      {withText && this.capitalizeFirstLetter(item.toxicIngredients[0].toxicity)}</> :
                    (!item.ingredients || item.ingredients.length === 0 ?
                      <><div className="toxic-label">?
                      </div>
                        {withText && LocalizationSingleton.getService().words.NoIngredients}</> : <><Icon source={IconSources.FONTAWESOME}
                          tooltip={LocalizationSingleton.getService().words.NotToxic} className="no-toxic-symbol"
                          name={"check"}
                          onClick={(e) => {
                            this.props.parentMachine.risksMx.addItemToInventory(item);
                            DialogSingleton.getManager().openDialog({
                              key: "added", hideHeader: true,
                              content: <div className="alert success">{LocalizationSingleton.getService().words.AddedToInventory}</div>, x: e.pageX, y: e.pageY
                            })
                            setTimeout(() => DialogSingleton.getManager().closeDialog("added"), 2000)
                          }} /> {withText && LocalizationSingleton.getService().words.NotToxic}</>)}</div></div>}
            />

            <div className="row pages">
              <div className="page-list-header">{LocalizationSingleton.getService().words.Pages}</div>
              {this.props.parentMachine.risksMx.searchPages?.pages && this.props.parentMachine.risksMx.searchPages.pages.filter((value) => value < 401)
                .map((page, index) => {
                  let searchPaging = this.props.parentMachine.risksMx.searchPages;
                  let inside = page === searchPaging?.currentPage ? page
                    : <div className="clickable-text" onClick={() => this.props.parentMachine.risksMx.searchFDC(true, undefined, page)}>{page}</div>;
                  if (index === 0 && page !== 1) {
                    return <div className="page-list-cap"><div className="clickable-text" onClick={() => this.props.parentMachine.risksMx.searchFDC(true, undefined, 1)}>1</div>... {inside}</div>
                  } else if (searchPaging && index === (searchPaging.pages?.length || 2) - 1 && page !== searchPaging?.totalPages) {
                    return <div className="page-list-cap">{inside}...<div className="clickable-text" onClick={() => this.props.parentMachine.risksMx.searchFDC(true, undefined, searchPaging?.totalPages)}>{Math.min(searchPaging?.totalPages || 400, 400)}</div></div>
                  }
                  return inside;
                })}</div>
          </div> : LocalizationSingleton.getService().words.NoResults}</div>
      <div className="citations">
        <div>{Keywords.citations.fdc}</div>
        <div>{Keywords.citations.p65}</div>
        <div><a target="_blank" rel="noopener noreferrer" href="https://www.boston25news.com/news/local/youd-be-surprised-groceries-banned-overseas-health-concerns-still-us-store-shelves/H6SPTXG3CFGOHJKSOV3HGVDEHE/">
          {LocalizationSingleton.getService().words.News}</a></div>
      </div>
    </div>;
  }
}