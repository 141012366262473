import { action, observable } from "mobx";
import { InventoryItem, Utils } from "../Utils";

export class CartMachine {    
    @observable cart: InventoryItem[] = [];
    static cartKey = "shelf_life_cart";

    constructor() {
        let cartString = window.localStorage.getItem(CartMachine.cartKey);
        if (cartString) {
            this.cart = Utils.parseInventoryItem(cartString);
        }
    }

    @action
    deleteFromCart(indeces: number[]): void {
        for (var i = indeces.length -1; i >= 0; i--){
            this.cart.splice(indeces[i],1);
        }
        this.updateCartLocalStorage();
    }

    @action
    emptyCart(): void {
        this.cart = [];
        this.updateCartLocalStorage();
    }

    @action
    addToCart(itemsToAdd: InventoryItem[]): void {
        this.cart = this.cart.concat(itemsToAdd);
        this.updateCartLocalStorage();
    }

    updateCartLocalStorage(): void {
        window.localStorage.setItem(CartMachine.cartKey, Utils.createInventoryString(this.cart))
    }

}