import { action, computed, observable } from "mobx";
import { InventoryItem, Utils } from "../Utils";

export class InventoryMachine {
    @observable inventory: InventoryItem[] = observable.array();
    
    static inventoryKey = "shelf_life_inventory";

    constructor() {
        let inventoryString = window.localStorage.getItem(InventoryMachine.inventoryKey);
        if (inventoryString) {
            this.inventory = Utils.parseInventoryItem(inventoryString);
        }
    } 
    
    @action
    deleteFromInventory(indeces: number[]): void {
        indeces = indeces.sort((n1,n2) => n1 - n2);
        for (var i = indeces.length -1; i >= 0; i--){
            this.inventory.splice(indeces[i],1);
        }
        this.updateInventoryLocalStorage();
    }

    @action
    deleteFromInventoryById(id: string): void {
        this.deleteFromInventory([this.inventory.findIndex((item) => item.id === id)]);
    }

    addDays(days: number, date: Date = new Date()): Date {
        var futureDate = date;
        futureDate.setDate(futureDate.getDate() + days);
        return futureDate;
    }

    @action
    addToInventory(itemsToAdd: InventoryItem[], ifEmptyDateDefault: boolean = true): void {
        ifEmptyDateDefault && itemsToAdd.forEach((item) => {
            if (!item.exp) {
                item.exp = this.addDays(10);
            }
        } );
        this.inventory = this.inventory.concat(itemsToAdd);
        this.updateInventoryLocalStorage();
    }

    @computed
    get expiredCount(): number {
        return this.inventory.filter((item) => item.exp && !(item.exp > new Date())).length;
    }

    updateInventoryLocalStorage(): void {
        window.localStorage.setItem(InventoryMachine.inventoryKey, Utils.createInventoryString(this.inventory))
    }


}
