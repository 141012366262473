import React from 'react';
import { observer } from 'mobx-react';
import { IconSources, Icon } from '../components/Icon';
import "./ShelfLifeApp.css";
import { Inventory } from './Inventory/Inventory';
import { BaseProps, GeneralMachine, ShelfViews } from './GeneralMachine';
import { action } from 'mobx';
import { HealthRisks } from './Toxic/HealthRisks';
import { Recall } from './Recall/Recall';
import { Route, Routes, NavLink } from 'react-router-dom';
import { LocalizationSingleton } from './services/localization/LocalizationSingleton';

@observer
export class ShelfLifeApp extends React.Component {
    public generalMachine?: GeneralMachine;

    componentWillMount() {
        this.generalMachine = new GeneralMachine();
    }

    componentDidMount() {
        this.generalMachine?.componentUpdates();
    }

    renderMenuItem(view: ShelfViews, text: string, icon: string, tooltip?: string, iconSource?: IconSources, count?: string): React.ReactElement {
        return <NavLink id={view}
            to={"/" + (view === ShelfViews.SHELF ? "" : view)}
            className={(isActive) => "navigation-bar-item" + (this.generalMachine!.view === view ? " nav-selected" : "")}>
            <div className="nav-icon-wrap"><Icon className={"nav-icon"} tooltip={tooltip}
                source={iconSource || IconSources.ASSETS} name={icon + ".svg"} />
                {count && <span className="nav-numbers">{count}</span>}
            </div>
            <div className="navigation-item-text">{text}</div>
        </NavLink>
    }

    renderMenu() {
        return <nav className={"navigation"}>
            {this.renderMenuItem(ShelfViews.SHELF, LocalizationSingleton.getService().words.Home, "fridge", LocalizationSingleton.getService().words.HomeTooltip)}
            {this.renderMenuItem(ShelfViews.HEALTHRISKS, LocalizationSingleton.getService().words.ProductLookup, "info", LocalizationSingleton.getService().words.ProductLookupTooltip)}
            {this.renderMenuItem(ShelfViews.RECALL, LocalizationSingleton.getService().words.Recall, "recall", LocalizationSingleton.getService().words.RecallTooltip)}

        </nav>;
    }

    render() {
        let translateTo = LocalizationSingleton.getService().language === "en" ? "es" : "en";

        if (this.generalMachine?.view) {
            return (
                <div className={"App app-wrapper "}>
                    {this.renderMenu()}
                    <Routes>
                        <Route path="/"
                            element={<AppContent children={<Inventory parentMachine={this.generalMachine!} />} parentMachine={this.generalMachine!} />} />
                        <Route path="/recall"
                            element={<AppContent children={<></>} parentMachine={this.generalMachine!} />} />
                        <Route path="/healthRisks"
                            element={<AppContent children={<HealthRisks parentMachine={this.generalMachine!} />} parentMachine={this.generalMachine!} />} />
                    </Routes>
                    <div className="app-footer">{LocalizationSingleton.getService().words.Language + LocalizationSingleton.getService().language}
                        <button onClick={() => this.generalMachine?.changeLanguage(translateTo)}>
                            {LocalizationSingleton.getService().words.Translate + translateTo}
                        </button>
                    </div>
                </div>);
        }
    }
}
interface AppContentProps extends BaseProps {
    children: React.ReactElement;
}

class AppContent extends React.Component<AppContentProps> {
    componentDidUpdate() {
        this.props.parentMachine?.componentUpdates();
    }
    render() {
        return <div className="app-content">
            {this.props.children}
            <div className={this.props.parentMachine!.view !== ShelfViews.RECALL ? "hide" : "show"}>
                <Recall parentMachine={this.props.parentMachine!} /></div>
        </div>;
    }
}

export default ShelfLifeApp;
