import React from 'react';
import { observable, action } from 'mobx';

import './Dialog.css';
import { observer } from 'mobx-react';
import { Icon, IconSources } from '../Icon';
import { LocalizationSingleton } from '../../shelfLife/services/localization/LocalizationSingleton';

export interface DialogProps {
    key: string;
    title?: string;
    onCancel?: () => void;
    onOk?: () => void;
    content?: React.ReactElement;
    className?: string;
    x?: number;
    y?: number;
    hideHeader?: boolean;
    disableDraggable?: boolean;
    hasDefaultFooter?: boolean;
}

interface DialogCmpProps extends DialogProps {
    controller: DialogController;
}

@observer
class DialogCmp extends React.Component<DialogCmpProps> {
    keyPressHandler(e: KeyboardEvent): void {
        if (e.key === "Enter") {
            this.props.onOk && this.props.onOk();
            this.closeDialog();
        }
        if (e.key === "Escape") {
            this.props.onCancel && this.props.onCancel();
            this.closeDialog();
        }
    }
    componentDidMount() {
        document.addEventListener("keydown", (e) => this.keyPressHandler(e), false);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", (e) => this.keyPressHandler(e), false);
    }

    closeDialog() {
        document.removeEventListener("keydown", (e) => this.keyPressHandler(e), false);
        this.props.controller.open = false;
    }

    render() {
        let left = this.props.controller.left || this.props.x;
        let top = this.props.controller.top || this.props.y || window.scrollY;
        let style = {}
        if (left || top) {
            style = { left: left + "px", top: top + "px" }
        }
        return this.props.controller.open ? <div className={"dialog " + this.props.className}
            style={style}
            ref={(ref) => {
                this.props.controller.dialogDiv = ref || undefined;
            }}>
            {!this.props.hideHeader && <div className="dialog-header" draggable={!this.props.disableDraggable}
                onDragStart={(event) => this.props.controller.onDragStart(event)}
                onDragEnd={(event) => this.props.controller.onDragEnd(event)}>
                <div>
                    {this.props.title}
                </div>
                <Icon source={IconSources.ASSETS} name="close.svg" className="btn close-btn"
                tooltip={LocalizationSingleton.getService().words.Close}
                    onClick={action(() => {
                        this.props.onCancel && this.props.onCancel();
                        this.closeDialog();
                    })} />
            </div>}
            <div className="dialog-content">
                {this.props.content}
            </div>
            {this.props.hasDefaultFooter && <div className="dlg-footer">
                {this.props.onOk && <button className="primary" onClick={action(() => {
                    this.props.onOk && this.props.onOk();
                    this.closeDialog();
                })}>OK</button>}
                {this.props.onCancel && <button onClick={action(() => {
                    this.props.onCancel && this.props.onCancel();
                    this.closeDialog();
                })}>Cancel</button>}
            </div>}
        </div> : null;
    }
}

class DialogController {
    @observable open: boolean = true;
    @observable top: number | undefined;
    @observable left: number | undefined;
    dialogDiv: HTMLDivElement | undefined;

    initialY: number = 0;
    initialX: number = 0;

    constructor(x?: number, y?: number) {
        this.initialX = x || 0;
        this.initialY = y || 0;
    }

    onDragStart(event: React.DragEvent<HTMLDivElement>) {
        this.initialX = event.pageX;
        this.initialY = event.pageY;
        this.dialogDiv && event.dataTransfer.setDragImage(this.dialogDiv, 0, 0)
    }

    @action
    onDragEnd(event: React.DragEvent<HTMLDivElement>) {
        if (this.dialogDiv) {
            this.left = (this.dialogDiv.offsetLeft || 0) + event.pageX - this.initialX;
            this.top = (this.dialogDiv.offsetTop || 0) + event.pageY - this.initialY;
        }
    }
}

function Dialog(props: DialogProps) {
    return <DialogCmp {...props} controller={new DialogController()} />
}

export default Dialog;