export class Keywords {
    public static modify = ["update", "modify", "change"];
    public static empty = ["empty", "clear"];
    public static delete = ["remove", "delete"];
    public static add = ["add"];

    public static prepositions = ["in", "on", "to"];

    public static dbExclude = ["a", "gas", "of uncertain purity", "of", "uncertain", "purity", 
    "internal use", "internal", "use", "mixed", "metallic", "soluble compounds", "soluble",
    "compounds", "hydrated", "technical grade", "technical", "grade", "complex mixture", "complex",
    "mixture", "heavy", "primary", "commercial grade", "commercial", "wholly vaporized", "wholly", "whole",
    "vaporized","anhydrous", "hydrated", "inhalation", "g/day", "except", "oral", "airborne",
    "unbound", "particles", "repairable", "size", "average", "chain", "length", "approximately",
    "60", "percent", "by", "weight", "and", "dermal", "in", "comibnation", "internal", "use", "mixed",
    "intravenous", "repellent", "326", "combined", "ingested", "condensates", "extracts", "inhalable",
    "biopersistent", "isomer", "alpha", "beta", "gamma", "trans", "cis", "salts", "expressed", "as",
    "purity", "soluble", "complex", "mixutre", "containing", "or", "more", "molecular", "dose", "rounded",
    "from", "8"];

    public static citations = {
        fdc: "U.S. Department of Agriculture, Agricultural Research Service. FoodData Central, 2019. fdc.nal.usda.gov",
        p65: "California Office of Environmental Health Hazard Assessment. Proposition 65, February 25, 2022. oehha.ca.gov/proposition-65"
    }
    public static hasKeyWord(s: string[], keyWord: string[]): boolean {
        return s.some(word => keyWord.includes(word));
    }

    public static hasNoModifyingWords(s: string[]): boolean {
        return !s.some(word => (Keywords.modify.concat(Keywords.add.concat(Keywords.delete.concat(Keywords.empty)))).includes(word));
    }
}