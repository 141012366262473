import React from "react";
import "./NutritionTable.css";
import { AbridgedFoodNutrient } from "../services/generated/models/AbridgedFoodNutrient";

export interface NutritionTableProps {
    nutrients: Array<AbridgedFoodNutrient>;
}

export class NutritionTable extends React.Component<NutritionTableProps> {
    render() {
        return this.props.nutrients.length > 0 ? <div className="nutrition-table">
            <div className="nutrition-table-header">
                Nutrition Facts
            </div>
            {this.props.nutrients.map((nutrient) => {
                return <div className="nutrition-table-row">
                    <div className="nutrient-name">{nutrient.nutrientName}</div>
                    <div className="nutrient-value">{nutrient.value + " " + nutrient.unitName}</div>
                </div>
            })}
        </div> : null;
    }
}
