import { action, computed, observable } from "mobx";
import React from "react";
import { CartMachine } from "./Cart/CartMachine";
import { InventoryMachine } from "./Inventory/InventoryMachine";
import { ShelfLifeService } from "./services/ShelfLifeService";
import { HealthRisksMachine } from "./Toxic/HealthRisksMachine";
import { InventoryItem } from "./Utils";
import { LocalizationSingleton } from "./services/localization/LocalizationSingleton";
import { DialogSingleton } from "../components/dialog/AppOverlay";

export interface BaseProps { parentMachine: GeneralMachine };

export enum ShelfViews {
    SHELF = "shelf",
    CART = "cart",
    CHAT = "chat",
    EXPIRATIONS = "expirations",
    HEALTHRISKS = "healthRisks",
    RECALL = "recall"
}

export class GeneralMachine {
    @observable view: ShelfViews = ShelfViews.SHELF;
    @observable expiredExpanded: boolean = true;
    @observable soonToExpiredExpanded: boolean = true;
    @observable restExpanded: boolean = false;


    public services: ShelfLifeService = new ShelfLifeService();
    public inventoryMx: InventoryMachine;
    public cartMx: CartMachine;
    public risksMx: HealthRisksMachine;

    constructor() {
        this.inventoryMx = new InventoryMachine();
        this.cartMx = new CartMachine();
        this.risksMx = new HealthRisksMachine(this);
    }

    changeLanguage(lang: string) {
        LocalizationSingleton.getService().changeLanguage(lang);
    }

    componentUpdates() {
        const href = window.location.href;
        const end = href.lastIndexOf("?");
        const location = href.substring(href.lastIndexOf("/") + 1, end > -1 ? end : undefined);
        const urlParams = new URLSearchParams(window.location.search);
        const view = ShelfViews[location.toUpperCase() as keyof typeof ShelfViews];
        if (this.view !== view) {
            this.setView(view, urlParams)
        }
    }

    @action
    setView(view?: ShelfViews, params?: URLSearchParams) {
        this.view = view ? view : ShelfViews.SHELF;
        if (params) {
            switch (this.view) {
                case ShelfViews.HEALTHRISKS: {
                    const search = params.get('q');
                    let page = params.get('page');
                    if (search) {
                        if (page && (+page > 400 || +page < 0)) {
                            DialogSingleton.getManager().openDialog({ title: LocalizationSingleton.getService().words.Error, key: search,
                                content: <div>{LocalizationSingleton.getService().words.LimitPagesError}</div> })
                            page = null;
                        }
                        this.risksMx.searchFDC(false, search, page !== null ? +page : undefined);
                    } else if (this.risksMx.search !== search) {
                        this.risksMx.updateUrl();
                    }
                }
            }
        }
    }

    @computed
    get inventory(): InventoryItem[] {
        return this.inventoryMx.inventory;
    }

    @computed
    get cart(): InventoryItem[] {
        return this.cartMx.cart;
    }

    @computed
    get cartMap(): Map<string, string[]> {
        let cartMap = new Map<string, string[]>();
        this.cart.forEach((item) => {
            let match = cartMap.get(item.category?.toLowerCase() || "");
            if (match) {
                match.push(item.name)
                cartMap.set(item.category?.toLowerCase() || "", match)
            } else {
                cartMap.set(item.category?.toLowerCase() || "", [item.name])
            }
        });
        return cartMap;
    }
}