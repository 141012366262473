import React from 'react';
import { observable, action } from 'mobx';
import { FiltersEnum } from "../../Toxic/HealthRisksMachine";
import { LocalizationServiceEN } from "./LocalizationStringsEN";
import { LocalizationServiceES } from './LocalizationStringsES';

export interface LocalizationStrings {
    // Common
    Close: string;
    Collapse: string;
    Expand: string;
    // Navigation
    Home: string;
    HomeTooltip: string;
    ProductLookup: string;
    ProductLookupTooltip: string;
    Expiration: string;
    Chat: string;
    Cart: string;
    Recall: string;
    RecallTooltip: string;
    // Health risks
    Chemical: string;
    Toxicity: string;
    Allergen: string;
    Nutrition: string;
    BarcodeTooltip: string;
    SearchTooltip: string;
    Actions: string;
    // Preferences
    Language: string;
    Translate: string;
    // Column
    ColumnTitleMap: Map<string, string>;
    // Inventory
    ExpirationDate: string;
    Quantity: string;
    BarcodeDisclaimer: string;
    Inventory: string;
    AddToInventory: string;
    DeleteFromInventory: string;
    // Expiration
    Expired: string;
    SevenDay: string;
    Others: string;
    // Chat
    HelloChat: string;
    ExpiresOn: string;
    NotHere: string;
    CartCleared: string;
    DeletedIngredients: (ingredients: string[]) => string;
    // Cart
    Name: string;
    Category: string;
    AddToCart: string;
    DeleteFromCart: string;
    // Health Risks
    FilterMapping: (id: FiltersEnum) => string;
    Error: string;
    WithToxicity: string;
    NotToxic: string;
    UknownToxicity: string;
    SortBy: string;
    ProductName: string;
    PublishedDate: string;
    FDCId: string;
    None: string;
    SortOrder: string;
    Ascending: string;
    Descending: string;
    Product: string;
    Brand: string;
    BrandOwner: string;
    Nutrients: string;
    UTC: string;
    Ingredients: string;
    ToxicIngredients: string;
    SearchPref: string;
    Preferences: string;
    Allergens: string;
    AddToAllergens: string;
    Filters: string;
    Columns: string;
    SavedChanges: string;
    ToxicDisclaimer: string;
    LimitPagesError: string;
    Loading: string;
    Results: string;
    NoIngredients: string;
    AddedToInventory: string;
    Pages: string;
    NoResults: string;
    News: string;
}

export class LocalizationSingleton {
    private static service: LocalizationSingleton;
    public words: LocalizationStrings = new LocalizationServiceEN();
    @observable language: string = "";
    
    private constructor() {
        let language = window.localStorage.getItem("language");
        this.changeLanguage(language || navigator.language.slice(0, 2));
    }
    
    @action
    changeLanguage(lang: string) {
        if (lang !== this.language) {
            switch (lang) {
                case "es":
                    this.words = new LocalizationServiceES();
                    this.language = "es";
                    break;
                default:
                    this.words = new LocalizationServiceEN();
                    this.language = "en";
                    break;
            }
        }
        window.localStorage.setItem("language", lang);
    }

    public static getService(): LocalizationSingleton {
        if (!LocalizationSingleton.service) {
            LocalizationSingleton.service = new LocalizationSingleton();
        }
        return LocalizationSingleton.service;
    }

}