import React from 'react';
import { observer } from 'mobx-react';
import { BaseProps } from '../GeneralMachine';
import { Icon, IconSources } from '../../components/Icon';
import { action, observable } from 'mobx';
import { TextInput } from '../../components/inputs/TextInput';
import { InventoryItem } from '../Utils';
import { Link } from 'react-router-dom';
import { LocalizationSingleton } from '../services/localization/LocalizationSingleton';

@observer
export class Inventory extends React.Component<BaseProps> {
  @observable private newInventoryRow = { name: "", exp: "", category: "", quantity: "" };

  @action
  addInventoryItem(inventoryItem?: InventoryItem): void {
    inventoryItem = inventoryItem || new InventoryItem(this.newInventoryRow.name, this.newInventoryRow.exp ? new Date(this.newInventoryRow.exp + "T00:00:00") : undefined,
      this.newInventoryRow.category, this.newInventoryRow.quantity)
    if (!inventoryItem.exp || inventoryItem.exp!.toString() === 'Invalid Date') {
      inventoryItem.exp = this.props.parentMachine.inventoryMx.addDays(15, new Date());
    }
    this.props.parentMachine.inventoryMx.addToInventory([inventoryItem]);
    this.newInventoryRow = { name: "", exp: "", category: "", quantity: "" };
  }

  @observable private newCartRow = { name: "", exp: "", category: "", quantity: "" };
  @action
  addNewCartItem(cartItem?: InventoryItem): void {
    cartItem = cartItem || new InventoryItem(this.newCartRow.name, this.newCartRow.exp ? new Date(this.newInventoryRow.exp + "T00:00:00") : undefined,
      this.newCartRow.category, this.newCartRow.quantity)
    this.props.parentMachine.cartMx.addToCart([cartItem]);
    this.newCartRow = { name: "", exp: "", category: "", quantity: "" };
  }

  render() {
    return <div className={LocalizationSingleton.getService().language}>
      <div>
        <div className='alert info'>
          {LocalizationSingleton.getService().words.BarcodeDisclaimer}
          "<Link to='/healthRisks'>
            {LocalizationSingleton.getService().words.ProductLookup}
          </Link>"
        </div>

        <div className='lists-wrapper'>
          <div>
            <div className="table-label">
              <Icon className={"nav-icon"} source={IconSources.ASSETS} name={"cart.svg"}
                tooltip={LocalizationSingleton.getService().words.Cart} />
              {LocalizationSingleton.getService().words.Cart} </div>
            <table id="cart" className="cart">
              <tbody>
                <tr className="header">
                  <th className="info-column">{LocalizationSingleton.getService().words.Name}</th>
                  <th className="info-column">{LocalizationSingleton.getService().words.Category}</th>
                  <th className="quantity-column info-column">{LocalizationSingleton.getService().words.Quantity}</th>
                  <th className="actions-column" />
                </tr>
                {this.props.parentMachine.cart.map((item, index) => {
                  return <tr key={item.name + index}>
                    <td className="info-column">{item.name}</td>
                    <td className="info-column">{item.category}</td>
                    <td className="quantity-column info-column">{item.quantity}</td>
                    <td className="actions-column"><Icon className={"nav-icon"}
                      source={IconSources.ASSETS} name={"fridgePlus.svg"} onClick={() => {
                        this.addInventoryItem(item)
                      }} tooltip={LocalizationSingleton.getService().words.AddToInventory} />
                      <Icon source={IconSources.FONTAWESOME} name="trash" onClick={() => {
                        this.props.parentMachine.cartMx.deleteFromCart([index]);
                      }} tooltip={LocalizationSingleton.getService().words.DeleteFromCart} /></td>
                  </tr>
                })}
                <tr key="new" onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    this.addNewCartItem();
                  }
                }}>
                  <td className="info-column"><TextInput value={this.newCartRow.name} onChange={(newValue) => this.newCartRow.name = newValue} /></td>
                  <td className="info-column"><TextInput value={this.newCartRow.category} onChange={(newValue) => this.newCartRow.category = newValue} /></td>
                  <td className="quantity-column info-column"><TextInput value={this.newCartRow.quantity} onChange={(newValue) => this.newCartRow.quantity = newValue} /></td>
                  <td className="actions-column"><Icon source={IconSources.FONTAWESOME} name="plus"
                    disabled={!this.newCartRow.name} tooltip={LocalizationSingleton.getService().words.AddToCart}
                    onClick={this.newCartRow.name ? () => {
                      this.addNewCartItem()
                    } : undefined} /></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <div className="table-label">
              <Icon className={"nav-icon"} source={IconSources.ASSETS} name={"fridge.svg"} />
              {LocalizationSingleton.getService().words.Inventory}</div>
            <table id="inventory" className="inventory">
              <tbody>
                <tr className="header">
                  <th className="actions-column" />
                  <th className="info-column">{LocalizationSingleton.getService().words.Name}</th>
                  <th className="info-column">{LocalizationSingleton.getService().words.Category}</th>
                  <th className="info-column">{LocalizationSingleton.getService().words.ExpirationDate}</th>
                </tr>
                {this.props.parentMachine.inventory.map((item, index) => {
                  return <tr key={item.name + index}>
                    <td className="actions-column"><Icon className={"nav-icon"}
                      source={IconSources.ASSETS} name={"cartPlus.svg"} onClick={() => {
                        this.addNewCartItem(item)
                      }} tooltip={LocalizationSingleton.getService().words.AddToCart} />
                      <Icon source={IconSources.FONTAWESOME} name="trash" onClick={() => {
                        this.props.parentMachine.inventoryMx.deleteFromInventory([index]);
                      }} tooltip={LocalizationSingleton.getService().words.DeleteFromInventory} /></td>
                    <td className="info-column">{item.name}</td>
                    <td className="info-column">{item.category}</td>
                    <td className="info-column">{item.exp?.toDateString()}</td>
                  </tr>
                })}
                <tr key="new" onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    this.addInventoryItem();
                  }
                }}>
                  <td className="actions-column"><Icon source={IconSources.FONTAWESOME} name="plus"
                    disabled={!this.newInventoryRow.name} tooltip={LocalizationSingleton.getService().words.AddToInventory}
                    onClick={this.newInventoryRow.name ? () => {
                      this.addInventoryItem()
                    } : undefined} /></td>
                  <td className="info-column"><TextInput value={this.newInventoryRow.name} onChange={(newValue) => this.newInventoryRow.name = newValue} /></td>
                  <td className="info-column"><TextInput value={this.newInventoryRow.category} onChange={(newValue) => this.newInventoryRow.category = newValue} /></td>
                  <td className="info-column"><input value={this.newInventoryRow.exp || ""} type="date"
                    onChange={(event) => this.newInventoryRow.exp = event.target.value} /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  }
}