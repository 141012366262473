/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AbridgedFoodItem } from '../models/AbridgedFoodItem';
import type { BrandedFoodItem } from '../models/BrandedFoodItem';
import type { FoodListCriteria } from '../models/FoodListCriteria';
import type { FoodsCriteria } from '../models/FoodsCriteria';
import type { FoodSearchCriteria } from '../models/FoodSearchCriteria';
import type { FoundationFoodItem } from '../models/FoundationFoodItem';
import type { inline_response_200 } from '../models/inline_response_200';
import type { SearchResult } from '../models/SearchResult';
import type { SRLegacyFoodItem } from '../models/SRLegacyFoodItem';
import type { SurveyFoodItem } from '../models/SurveyFoodItem';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FdcService {

    /**
     * Fetches details for one food item by FDC ID
     * Retrieves a single food item by an FDC ID. Optional format and nutrients can be specified.
     * @param apiKey API key, security
     * @param fdcId FDC id of the food to retrieve
     * @param format Optional. 'abridged' for an abridged set of elements, 'full' for all elements (default).
     * @param nutrients Optional. List of up to 25 nutrient numbers. Only the nutrient information for the specified nutrients will be returned. Should be comma separated list (e.g. nutrients=203,204) or repeating parameters (e.g. nutrients=203&nutrients=204). If a food does not have any matching nutrients, the food will be returned with an empty foodNutrients element.
     * @returns inline_response_200 One food result.
     * @throws ApiError
     */
    public static getFood(
apiKey: string,
fdcId: string,
format?: 'abridged' | 'full',
nutrients?: Array<number>,
): CancelablePromise<inline_response_200> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/food/{fdcId}',
            path: {
                'fdcId': fdcId,
            },
            query: {
                'api_key': apiKey,
                'format': format,
                'nutrients': nutrients,
            },
            errors: {
                400: `bad input parameter`,
                404: `no results found`,
            },
        });
    }

    /**
     * Fetches details for multiple food items using input FDC IDs
     * Retrieves a list of food items by a list of up to 20 FDC IDs. Optional format and nutrients can be specified. Invalid FDC ID's or ones that are not found are omitted and an empty set is returned if there are no matches.
     * @param apiKey API key, security
     * @param fdcIds List of multiple FDC ID's. Should be comma separated list (e.g. fdcIds=534358,373052) or repeating parameters (e.g. fdcIds=534358&fdcIds=373052).
     * @param format Optional. 'abridged' for an abridged set of elements, 'full' for all elements (default).
     * @param nutrients Optional. List of up to 25 nutrient numbers. Only the nutrient information for the specified nutrients will be returned. Should be comma separated list (e.g. nutrients=203,204) or repeating parameters (e.g. nutrients=203&nutrients=204). If a food does not have any matching nutrients, the food will be returned with an empty foodNutrients element.
     * @returns any List of Food details matching specified FDC ID's. Invalid FDC ID's or ones that are not found are omitted.
     * @throws ApiError
     */
    public static getFoods(
apiKey: string,
fdcIds: Array<string>,
format?: 'abridged' | 'full',
nutrients?: Array<number>,
): CancelablePromise<Array<(AbridgedFoodItem | BrandedFoodItem | FoundationFoodItem | SRLegacyFoodItem | SurveyFoodItem)>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/foods',
            query: {
                'api_key': apiKey,
                'fdcIds': fdcIds,
                'format': format,
                'nutrients': nutrients,
            },
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Fetches details for multiple food items using input FDC IDs
     * Retrieves a list of food items by a list of up to 20 FDC IDs. Optional format and nutrients can be specified. Invalid FDC ID's or ones that are not found are omitted and an empty set is returned if there are no matches.
     * @param requestBody 
     * @returns any List of Food details matching specified FDC ID's. Invalid FDC ID's or ones that are not found are omitted.
     * @throws ApiError
     */
    public static postFoods(
requestBody: FoodsCriteria,
): CancelablePromise<Array<(AbridgedFoodItem | BrandedFoodItem | FoundationFoodItem | SRLegacyFoodItem | SurveyFoodItem)>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/foods',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Returns a paged list of foods, in the 'abridged' format
     * Retrieves a paged list of foods. Use the pageNumber parameter to page through the entire result set.
     * @param apiKey API key, security
     * @param dataType Optional. Filter on a specific data type; specify one or more values in an array.
     * @param pageSize Optional. Maximum number of results to return for the current page. Default is 50.
     * @param pageNumber Optional. Page number to retrieve. The offset into the overall result set is expressed as (pageNumber * pageSize)
     * @param sortBy Optional. Specify one of the possible values to sort by that field. Note, dataType.keyword will be dataType and lowercaseDescription.keyword will be description in future releases.
     * @param sortOrder Optional. The sort direction for the results. Only applicable if sortBy is specified.
     * @returns AbridgedFoodItem List of foods for the requested page
     * @throws ApiError
     */
    public static getFoodsList(
apiKey: string,
dataType?: Array<'Branded' | 'Foundation' | 'Survey (FNDDS)' | 'SR Legacy'>,
pageSize?: number,
pageNumber?: number,
sortBy?: 'dataType.keyword' | 'lowercaseDescription.keyword' | 'fdcId' | 'publishedDate',
sortOrder?: 'asc' | 'desc',
): CancelablePromise<Array<AbridgedFoodItem>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/foods/list',
            query: {
                'api_key': apiKey,
                'dataType': dataType,
                'pageSize': pageSize,
                'pageNumber': pageNumber,
                'sortBy': sortBy,
                'sortOrder': sortOrder,
            },
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Returns a paged list of foods, in the 'abridged' format
     * Retrieves a paged list of foods. Use the pageNumber parameter to page through the entire result set.
     * @param requestBody 
     * @returns AbridgedFoodItem List of foods for the requested page
     * @throws ApiError
     */
    public static postFoodsList(
requestBody: FoodListCriteria,
): CancelablePromise<Array<AbridgedFoodItem>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/foods/list',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Returns a list of foods that matched search (query) keywords
     * Search for foods using keywords. Results can be filtered by dataType and there are options for result page sizes or sorting.
     * @param apiKey API key, security
     * @param query One or more search terms.  The string may include [search operators](https://fdc.nal.usda.gov/help.html#bkmk-2)
     * @param dataType Optional. Filter on a specific data type; specify one or more values in an array.
     * @param pageSize Optional. Maximum number of results to return for the current page. Default is 50.
     * @param pageNumber Optional. Page number to retrieve. The offset into the overall result set is expressed as (pageNumber * pageSize)
     * @param sortBy Optional. Specify one of the possible values to sort by that field. Note, dataType.keyword will be dataType and lowercaseDescription.keyword will be description in future releases.
     * @param sortOrder Optional. The sort direction for the results. Only applicable if sortBy is specified.
     * @param brandOwner Optional. Filter results based on the brand owner of the food. Only applies to Branded Foods
     * @returns SearchResult List of foods that matched search terms
     * @throws ApiError
     */
    public static getFoodsSearch(
apiKey: string,
query: string,
dataType?: Array<'Branded' | 'Foundation' | 'Survey (FNDDS)' | 'SR Legacy'>,
pageSize?: number,
pageNumber?: number,
sortBy?: 'dataType.keyword' | 'lowercaseDescription.keyword' | 'fdcId' | 'publishedDate',
sortOrder?: 'asc' | 'desc',
brandOwner?: string,
): CancelablePromise<SearchResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/foods/search',
            query: {
                'api_key': apiKey,
                'query': query,
                'dataType': ['Branded'],
                'pageSize': pageSize,
                'pageNumber': pageNumber,
                'sortBy': sortBy,
                'sortOrder': sortOrder,
                'brandOwner': brandOwner,
            },
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Returns a list of foods that matched search (query) keywords
     * Search for foods using keywords. Results can be filtered by dataType and there are options for result page sizes or sorting.
     * @param requestBody The query string may also include standard [search operators](https://fdc.nal.usda.gov/help.html#bkmk-2)
     * @returns SearchResult List of foods that matched search terms
     * @throws ApiError
     */
    public static postFoodsSearch(
requestBody: FoodSearchCriteria,
): CancelablePromise<SearchResult> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/foods/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Returns this documentation in JSON format
     * The OpenAPI 3.0 specification for the FDC API rendered as JSON (JavaScript Object Notation)
     * @returns any JSON rendering of OpenAPI 3.0 specification
     * @throws ApiError
     */
    public static getJsonSpec(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/json-spec',
        });
    }

    /**
     * Returns this documentation in JSON format
     * The OpenAPI 3.0 specification for the FDC API rendered as YAML (YAML Ain't Markup Language)
     * @returns any YAML rendering of OpenAPI 3.0 specification
     * @throws ApiError
     */
    public static getYamlSpec(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/yaml-spec',
        });
    }

}