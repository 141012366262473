import { FiltersEnum } from "../../Toxic/HealthRisksMachine";
import { LocalizationStrings } from "./LocalizationSingleton";

export class LocalizationServiceES implements LocalizationStrings {
    Close = "Cerrar";
    Collapse = "Colapsar";
    Expand = "Expandir";
    Home = "Casa";
    HomeTooltip = "Inventario y Carrito";
    ProductLookup = "Búsqueda de Producto";
    ProductLookupTooltip = "Búsqueda de ingredientes toxicos en alimentos";
    Expiration = "Expiración";
    Chat = "Chat";
    Cart = "Carrito";
    Inventory = "Inventario";
    Recall = "Retiradas";
    RecallTooltip = "Retiradas de la CDC en Estados Unidos";
    Chemical ="químico";
    Toxicity = "toxicidad";
    Allergen = "alergénico";
    Nutrition = "Valores Nutricionales";
    BarcodeTooltip = "Escaneador de codigo de barras";
    SearchTooltip = "Buscar producto";
    Language = "Idioma activo: ";
    Translate = "Traducir al ";
    Brand = "Marca";
    BrandOwner = "Dueño de Marca";
    FDCId = "Numero FDC";
    Name = "Nombre";
    Product = "Producto";
    Category = "Categoría";
    Nutrients = "Nutrientes"
    UTC = "GTIN o UPC";
    Ingredients = "Ingredientes";
    ToxicIngredients = "Ingredientes Tóxicos"
    ColumnTitleMap = new Map<string, string>([["description", this.Product],
    ["brandName", this.Brand],
    ["brandOwner", this.BrandOwner],
    ["fdcId", this.FDCId],
    ["foodCategory", this.Category],
    ["foodNutrients", this.Nutrients],
    ["gtinUpc", this.UTC],
    ["ingredients", this.Ingredients],
    ["toxicIngredients", this.ToxicIngredients],
    ["shortDescription", this.Product],
    ["householdServingFullText", "Conocido Como"],
    ["servingSize", "Tamaño de la porción"],
    ["servingSizeUnit", ""],
    ["packageWeight", "Peso"],
    ["tradeChannels", "Canales Comerciales"],
    ["marketCountry", "Mercado País"]
    ]);
    BarcodeDisclaimer = "Para agregar un producto usando el código de barras (GTIN o UPC), favor de ir a la sección ";
    AddToInventory = "Agregar a inventario";
    DeleteFromInventory = "Borrar de inventario";
    ExpirationDate = "Fecha de Expiración";
    Quantity = "Cantidad";
    Expired = "Expirado";
    SevenDay = "7 días o menos hasta expiración";
    Others = "Otros";
    HelloChat = "Hola, ¿Cómo te puedo ayudar?";
    ExpiresOn = " expira el ";
    NotHere = "Lo sentimos, el producto no está en tu inventario";
    CartCleared = "Tu carrito se ha vaciado";
    AddToCart = "Agregar al carrito";
    DeleteFromCart = "Borrar del carrito";
    Actions = "Acciones";
    DeletedIngredients = (items: string[]) => items.join(", ") + " se han borrado de tu carrito";
    FilterMapping(id: FiltersEnum): string {
        switch (id) {
            case (FiltersEnum.ALLERGEN):
              return "Alergénico personales";
            case (FiltersEnum.NOT_TOXIC):
              return "Posiblemente no tóxico";
            case (FiltersEnum.TOXIC):
              return "Tóxico";
            case (FiltersEnum.UNKNOWN):
              return "Ingredientes no encontrados";
            default:
              return id;
          }
    }
    Error = "Error";
    WithToxicity = " con toxicidad ";
    NotToxic = "Sin ingredientes tóxicos identificados";
    UknownToxicity = "Toxicidad no se puede calcular";
    SortBy = "Organizar por";
    ProductName = "Nombre de Producto";
    PublishedDate = "Fecha de publicación";
    None = "Ninguno";
    SortOrder = "Orden";
    Ascending = "Ascender";
    Descending = "Descender";
    SearchPref = "Preferencias de búsqueda";
    Preferences = "Preferencias";
    Allergens = "Alergénicos";
    AddToAllergens = "Agregar a Alergénicos";
    Filters = "Filtros";
    Columns = "Columnas";
    SavedChanges = "Cambios guardados";
    ToxicDisclaimer = "En esta sección, intentamos identificar productos con ingredientes tóxicos. La información proporcionada puede ser incorrecta. Favor de usar con precaución.";
    LimitPagesError = "El número de página excede nuestros límites; favor de seleccionar un número de página entre 1 y 400"
    Loading = "Cargando...";
    Results = "Resultados";
    NoIngredients = "Ingredientes no revelados";
    AddedToInventory = "Agregado a inventario";
    Pages = "Paginas";
    NoResults = "Sin resultados";
    News = "En las noticias";
}