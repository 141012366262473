import { observer } from 'mobx-react';
import React from 'react';
import { Icon, IconSources } from '../Icon';
import './CategoricalList.css';
import { LocalizationSingleton } from '../../shelfLife/services/localization/LocalizationSingleton';

export interface Category {
    header?: string|React.ReactElement;
    items: (string|React.ReactElement)[];    
    expanded?: boolean;
    onExpandedChange?: () => void;
}

export interface CategoricalListProps {
    categories: Category[];
    showEmptyCategories?: boolean;
}

@observer
export class CategoricalList extends React.Component<CategoricalListProps> {
    render() {
        let categories = this.props.categories;
        if (!this.props.showEmptyCategories) {
            categories = categories.filter((cat) => cat.items.length > 0);
        }
        return <div className="category-list" onClick={(event) => event.stopPropagation()}>
            {categories.map((category, index) => <div className="category-wrapper" key={(typeof category) === "string" ? category.toString() : (index + "")}>
            <div className="category-header">
                {category.onExpandedChange && <Icon name={category.expanded ? "angle-up" : "angle-down"} source={IconSources.FONTAWESOME} 
                tooltip={category.expanded ? LocalizationSingleton.getService().words.Collapse : LocalizationSingleton.getService().words.Expand}
                onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    category.onExpandedChange && category.onExpandedChange()}}/>}
                {category.header}
                </div>
            {!(category.expanded === false) && <div className="category-items">
                {category.items.map((item, i) => <div key={(typeof item) === "string" ? item.toString() : (i + "")} className="category-item">{item}</div>)}
                </div>}
        </div>)}</div>
    }
}