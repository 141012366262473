import { Keywords } from "../Keywords";
import { FdcKey } from "./APIKey";
import { FdcService } from "./generated/services/FdcService";
import { SearchResultFood } from "./generated/models/SearchResultFood";
import { SearchResult } from "./generated/models/SearchResult";
import { LocalizationSingleton } from "./localization/LocalizationSingleton";


export interface ToxicChemical {
    chemical: string;
    toxicity: string;
    dateListed: string;
    matched: string;
}

export interface FdcFood extends SearchResultFood {
    toxicIngredients?: ToxicChemical[];
}

export class ShelfLifeService {
    toxicityMap = new Map<string, ToxicChemical>();
    static punctuationRegex = /[.,/#!$%^*;:{}=\-_`~()[\]]/g;

    constructor() {
        this.readP65File();
        this.addMissingToxicIngredients();
    }

    addMissingToxicIngredients() {
        this.toxicityMap.set("brominated vegetable oil", { chemical: "brominated vegetable oil", toxicity: "neurological problems, increase fat and cholesterol", dateListed: "", matched: "" });
        this.toxicityMap.set("propyl paraben", { chemical: "propyl paraben", toxicity: "reproductive harm", dateListed: "", matched: "" });
        this.toxicityMap.set("sodium nitrate", { chemical: "sodium nitrate", toxicity: "cancer (in cured meats or in excess)", dateListed: "", matched: "" });
    }
    // To-do search criteria
    async searchFDC(search: string, pageNumber: number = 1, saveSearch: boolean = true, personalToxicity: string[] = [],
        sortBy?: 'dataType.keyword' | 'lowercaseDescription.keyword' | 'fdcId' | 'publishedDate',
        sortOrder?: 'asc' | 'desc',
        brandOwner?: string): Promise<SearchResult> {
        const response = await FdcService.getFoodsSearch(FdcKey, search, ["Branded"], 25, pageNumber, sortBy, sortOrder, brandOwner);
        const personalToxMap = new Map<string, string>();
        personalToxicity.forEach((toxic) => personalToxMap.set(toxic.toLowerCase().trim(), toxic));
        if (saveSearch) {
            response.foods?.forEach((food: FdcFood) => {
                if (food.ingredients && food.ingredients.length > 0) {
                    let splitIng = food.ingredients.toLowerCase().split(" and ");
                    let ingredients: string[] = [];
                    splitIng.forEach((ing) => {
                        ingredients = ingredients.concat(ing.split(ShelfLifeService.punctuationRegex));
                    })
                    food.toxicIngredients = this.ingredientsToxicity(ingredients, personalToxMap);
                }
            })
        }
        return response;
    }

    async readP65File(): Promise<void> {
        const headers = [LocalizationSingleton.getService().words.Chemical, 
            LocalizationSingleton.getService().words.Toxicity, 
            "Listing Mechanism", "CAS No.", "dateListed", 
            "NSRL or MADL (µg/day)a", "empty", "empty", "empty", 
            "empty", "empty", "empty", "empty"];
        fetch("./p65chemicalslist.csv", {
            method: 'get',
            headers: {
                'content-type': 'text/csv;charset=UTF-8'
            }
        })
            .then(res => res.text())
            .then(data => {
                const { parse } = require('csv-parse/lib/sync');
                try{let output = parse(data, {
                    delimiter: ',',
                    columns: headers,
                });
                output.forEach((chemical: ToxicChemical) => {
                    if (chemical && chemical.chemical && chemical.toxicity && chemical.chemical.indexOf("(") > -1) {
                        let chem = chemical.chemical.split("(NOTE")[0];
                        let openPar = chem.indexOf("(");
                        let closePar = chem.indexOf(")");
                        let parWord = chem.slice(openPar + 1, closePar).toLowerCase().trim();

                        let origWord = chem.slice(0, openPar) + (closePar + 2 < chem.length
                            ? chem.slice(closePar + 2) : "")
                        this.toxicityMap.set(chem.toLowerCase().trim(), chemical);
                        this.toxicityMap.set(origWord.toLowerCase().trim(), chemical);
                        Keywords.dbExclude.indexOf(parWord) === -1 && this.toxicityMap.set(parWord, chemical);
                    } else {
                        chemical && chemical.chemical
                            && this.toxicityMap.set(chemical.chemical.toLowerCase().trim(), chemical)
                    }
                });
    }catch {
        //nothing
    }});
    }

    getIngredientParts(ingredient: string): string[] {
        return ingredient.toLocaleLowerCase().trim().replace(ShelfLifeService.punctuationRegex, "").split(" ");
    }

    ingredientsToxicity(ingredients: string[], personalToxMap: Map<string, string>): ToxicChemical[] {
        let toxics: ToxicChemical[] = [];
        let toxicsMap = new Map<string, boolean>();
        ingredients.forEach((ingredient) => {
            if (ingredient.trim().length > 0) {
                let lookup = ingredient.toLowerCase().trim();
                let singPlur = lookup[lookup.length - 1] === "s" ? lookup.substring(0, lookup.length - 1) : lookup + "s";
                let match = this.toxicityMap.get(lookup) || this.toxicityMap.get(singPlur);
                let pmatch = personalToxMap.get(lookup) || personalToxMap.get(singPlur);
                let parts = this.getIngredientParts(ingredient);
                if (!match) {
                    parts.forEach((part) => {
                        match = this.toxicityMap.get(part);
                        if (match && !toxicsMap.get(match.chemical)) {
                            toxics.push({ ...match, matched: ingredient.toLowerCase() });
                            toxicsMap.set(match.chemical, true);
                        }
                    })
                } else if (!toxicsMap.get(match.chemical)) {
                    toxics.push({ ...match, matched: ingredient.toLowerCase() });
                    toxicsMap.set(match.chemical, true);
                }
                if (!pmatch) {
                    parts.forEach((part) => {
                        let singPlurPart = part[part.length - 1] === "s" ? part.substring(0, part.length - 1) : part + "s";
                        pmatch = personalToxMap.get(part) || personalToxMap.get(singPlurPart);
                        if (pmatch && !toxicsMap.get(pmatch)) {
                            toxics.push({ chemical: pmatch, toxicity: "allergen", dateListed: "", matched: ingredient.toLowerCase() });
                            toxicsMap.set(pmatch, true);
                        }
                    })
                } else if (!toxicsMap.get(pmatch)) {
                    toxics.push({ chemical: pmatch, toxicity: "allergen", dateListed: "", matched: ingredient.toLowerCase() });
                    toxicsMap.set(pmatch, true);
                }
            }

        });
        return toxics;
    }
}