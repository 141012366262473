import { FiltersEnum } from "../../Toxic/HealthRisksMachine";
import { LocalizationStrings } from "./LocalizationSingleton";

export class LocalizationServiceEN implements LocalizationStrings {
    Close = "Close";
    Collapse = "Collapse";
    Expand = "Expand";
    Home = "Home";
    HomeTooltip = "Inventory and Cart";
    ProductLookup = "Product Lookup";
    ProductLookupTooltip = "Lookup toxicity of food products";
    Expiration = "Expirations";
    Chat = "Chat";
    Inventory = "Inventory";
    Cart = "Shopping List";
    Recall = "Recall";
    RecallTooltip = "CDC Recalls in US";
    Chemical = "chemical";
    Toxicity = "toxicity";
    Allergen = "allergen";
    Nutrition = "Nutrition Facts";
    Language = "Current language: ";
    Translate = "Translate to ";
    Brand = "Brand";
    BrandOwner = "Brand owner";
    FDCId = "FDC Id";
    Name = "Name";
    Product = "Product";
    Category = "Category";
    Nutrients = "Nutrients";
    BarcodeTooltip = "Barcode scanner";
    SearchTooltip = "Product Search";
    UTC = "GTIN or UPC";
    Ingredients = "Ingredients";
    ToxicIngredients = "Toxic Ingredients"
    LimitPagesError = "The page number excedes our limits; please select a page number between 1 and 400"
    ColumnTitleMap = new Map<string, string>([["description", this.Product],
    ["brandName", this.Brand],
    ["brandOwner", this.BrandOwner],
    ["fdcId", this.FDCId],
    ["foodCategory", this.Category],
    ["foodNutrients", this.Nutrients],
    ["gtinUpc", this.UTC],
    ["ingredients", this.Ingredients],
    ["toxicIngredients", this.ToxicIngredients],
    ["shortDescription", this.Product],
    ["householdServingFullText", "Known As"],
    ["servingSize", "Serving Size"],
    ["servingSizeUnit", ""],
    ["packageWeight", "Package Weight"],
    ["tradeChannels", "Trade Channels"],
    ["marketCountry", "Market Country"]
    ]);
    BarcodeDisclaimer = "To add a product by its barcode (GTIN or UPC) number, please go to ";
    ExpirationDate = "Expiration Date";
    AddToInventory = "Add to Inventory";
    DeleteFromInventory = "Delete from Inventory";
    Quantity = "Quantity";
    Expired = "Expired";
    SevenDay = "7 or less days until expired";
    Others = "Rest";
    HelloChat = "Hello, how can I help you?";
    ExpiresOn = " expires on ";
    NotHere = "Sorry, the product you inquired about is not in your inventory";
    CartCleared = "Cart has been cleared";
    AddToCart = "Add to Cart";
    DeleteFromCart = "Delete from Cart";
    Actions = "Actions";
    DeletedIngredients = (items: string[]) => "Deleted " + items.join(", ") + " from your cart";
    
    FilterMapping(id: FiltersEnum): string {
        switch (id) {
            case (FiltersEnum.ALLERGEN):
              return "Personal allergens";
            case (FiltersEnum.NOT_TOXIC):
              return "Possibly non-toxic";
            case (FiltersEnum.TOXIC):
              return "Toxic";
            case (FiltersEnum.UNKNOWN):
              return "Ingredients not found";
            default:
              return id;
          }
    }
    Error = "Error";
    WithToxicity = " with type of toxicity ";
    NotToxic = "No toxic ingredients identified";
    UknownToxicity = "Unknown since ingredients were not disclosed";
    SortBy = "Sort by";
    ProductName = "Product name";
    PublishedDate = "Published Date";
    None = "None";
    SortOrder = "Sort Order";
    Ascending = "Ascending";
    Descending = "Descending";
    SearchPref = "Search Options";
    Preferences = "Options";
    Allergens = "Allergens";
    AddToAllergens = "Add to Allergens";
    Filters = "Filters";
    Columns = "Columns";
    SavedChanges = "Saved changes, if any";
    ToxicDisclaimer = "This is a section where we try our bests to tell you if products have known toxic ingredients. This is under progress and the information we provide is to the best of our abilities.";
    Loading = "Loading...";
    Results = "Results";
    NoIngredients = "No ingredients disclosed";
    AddedToInventory = "Added to Inventory";
    Pages = "Pages";
    NoResults = "No results to show";
    News = "On the news";
}