import { observable } from "mobx";
import { FdcFood, ToxicChemical } from "./services/ShelfLifeService";
import { FiltersEnum } from "./Toxic/HealthRisksMachine";

export class InventoryItem {
    @observable name: string; 
    @observable exp?: Date; 
    @observable category?: string; 
    @observable quantity?: string;
    @observable brand?: string;
    toxicIngredients?: ToxicChemical[];
    
    id: string;
    constructor(name: string, exp?: Date, category?: string, quantity?: string, id?: string) {
        this.name = name;
        this.exp = exp;
        this.category = category;
        this.quantity = quantity;
        this.id = id || name + (new Date());
    }
}

export class Utils {
    static newRow = "..";
    static newField = ";;";

    static parseInventoryItem(inventoryString: string): InventoryItem[] {
        let inventory = inventoryString.split(Utils.newRow).map((item) => {
            let json = JSON.parse(item);
            json.exp = new Date(json.exp);
            json.quantity = json.quantity || "1";
            return json;
    });
    return inventory;
    }

    static createInventoryString(list: InventoryItem[]): string {
        return list.map((row) => JSON.stringify(row)).join(Utils.newRow);
    }

    static sort(inventory: InventoryItem[]): InventoryItem[] {
        return inventory.sort((item1, item2) => {
            let date1 = item1.exp || new Date();
            let date2 = item2.exp || new Date();
            if (date1 < date2) {
                return -1;
              }
              if (date1 > date2) {
                return 1;
              }
              // a must be equal to b
              return 0;
        });
    }

    static getFoodStatus(food: FdcFood): FiltersEnum[] {
        let filters = [];
        if ((food.ingredients?.length || 0) > 0) {
            if (food.toxicIngredients && food.toxicIngredients.length > 0) {
                if (food.toxicIngredients.some((chemical) => chemical.toxicity === "allergen")) {
                    filters.push(FiltersEnum.ALLERGEN);
                } 
                if (food.toxicIngredients.some((chemical) => chemical.toxicity !== "allergen")) {
                    filters.push(FiltersEnum.TOXIC);
                }
                return filters;
            } else {
                return [FiltersEnum.NOT_TOXIC]
            }
        } else {
            return [FiltersEnum.UNKNOWN]
        }
    }
}


    