import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import "./Table.css";
import { Icon, IconSources } from '../Icon';
import { LocalizationSingleton } from '../../shelfLife/services/localization/LocalizationSingleton';

export interface Column<T> {
    title: (id: string) => string;
    id: string;
    header?: boolean;
    titleRow?: boolean;
    className?: string;
    hideTitle?: boolean;
    rowRenderer?: (item: T) => React.ReactElement;
}

export interface TableProps<T> {
    columns: (mobileView?: boolean) => Column<T>[];
    rows: T[];
    id: string;
    title: string;
    className?: string;
    rowHeader?: (item: T, withText?: boolean) => React.ReactElement;
    onTouchAndDragged?: (item: T) => void;
}

@observer
export class Table<T> extends React.Component<TableProps<T>> {
    @observable expanded?: number;
    @observable touchedState = observable({
        left: 0,
        originalOffset: 0,
        beingTouched: false,
        itemId: -1
    });

    @action
    onExpandChange(index: number) {
        if (this.expanded === index) {
            this.expanded = undefined;
        } else {
            this.expanded = index;
        }
    }

    renderDetails(index: number) {
        return this.expanded === index
            ? <tr><td colSpan={4}>{this.renderExpandedView(false, false, [this.props.rows[index]])}</td></tr>
            : <></>
    }

    renderExpandedView(singleItem?: boolean, mobile?: boolean, rows?: T[]) {
        let numOfLines = -1;
        let groupBy = (item: T, rowIndex: number) => {
            let line = 0;
            let groupedByLine = new Map<number, React.ReactElement[]>();
            let rowHeader = this.props.rowHeader && (singleItem ? this.props.rowHeader(item, true) : <div className="info-column">
                <Icon name={rowIndex === this.expanded ? "angle-up" : "angle-down"} source={IconSources.FONTAWESOME}/>
                {this.props.rowHeader(item)}
                </div>);
            rowHeader && (singleItem || mobile) && groupedByLine.set(0, [rowHeader])
            this.props.columns(true).forEach((col) => {
                let val = item[col.id as keyof T];
                let colRn = <div className={"single-table-item " + col.className} key={col.id + " " + rowIndex}>
                    {!col.header && !col.hideTitle && <b>
                        {col.title(col.id)}
                    </b>}
                    {col.rowRenderer ? col.rowRenderer(item) : (val ? val as any as String : "Unknown")}
                </div>;
                line = col.rowRenderer && !col.header ? line + 1 : line;
                if (val || (mobile ? col.header : !(col.header || col.titleRow))) {
                    if (line === 0 && !(col.header || col.titleRow)) {
                        line = line + 1
                    }
                    let match = groupedByLine.get(line);
                    if (match) {
                        match.push(colRn);
                    } else {
                        groupedByLine.set(line, [colRn]);
                    }
                }

            });
            numOfLines = Math.max(numOfLines, line);
            return groupedByLine;
        }
        return <div className={(mobile ? "mobile-column mobile-view" : "") + (singleItem ? " single-result" : "")}>
            {(rows || this.props.rows).map((item, index) => {
                let lines = groupBy(item, index);
                let rendering = [];
                for (let i = 0; i <= numOfLines; i++) {
                    let match = lines.get(i);
                    if (match && match.length > 0) {
                        let item = <div id={"row_" + index + "_" + i} className={mobile ? "mobile-row line" : "row line"}>
                            {match}
                        </div>;
                        rendering.push(item)
                        if (mobile && this.expanded !== index) {
                            break;
                        }
                    }
                }
                return <div>
                    <div className={(mobile ? "mobile-row " : "") + (this.expanded === index ? "expanded" : "collapsed")}
                        key={index} style={{
                            left: this.touchedState.itemId === index
                                ? this.touchedState.left + "px" : undefined
                        }}
                        // onTouchStart={action((event) => {    
                        //     event.preventDefault();                
                        //     if (event.touches.length === 1 && this.props.onTouchAndDragged) {
                        //         this.touchedState.originalOffset = event.targetTouches.item(0)?.clientX;
                        //         this.touchedState.beingTouched = true;
                        //         this.touchedState.itemId = index;
                        //         console.log(index)
                        //     }
                        // })} 
                        onClick={() => this.onExpandChange(index)}
                    // onTouchMove={action((event) => {
                    //         if (this.touchedState.beingTouched) { 
                    //           let deltaX = event.targetTouches.item(0)?.clientX - this.touchedState.originalOffset;
                    //           if (deltaX > 0) {
                    //             this.touchedState.left = deltaX;                                                       
                    //     event.preventDefault();
                    //           } else {
                    //               this.touchedState.beingTouched = false;
                    //               this.expanded.get(index) ? this.expanded.set(index, false) : this.expanded.set(index, true)
                    //           }
                    //         }
                    // })} 
                    // onTouchEnd={action((event) => {
                    //     event.preventDefault();
                    //     if (this.touchedState.left > 100) {
                    //         this.props.onTouchAndDragged && this.props.onTouchAndDragged(item);
                    //     }
                    //     this.touchedState.beingTouched = false;
                    //     this.touchedState.itemId = -1;
                    //     this.touchedState.left = this.touchedState.originalOffset;
                    // })}
                    >
                        <div className={mobile ? "mobile-column contents" : "contents"}>{rendering}</div>
                    </div></div>
            })}
        </div>
    }

    render() {
        let cols = this.props.columns(false).filter((col) => col.header || col.titleRow);
        return this.props.rows.length === 1 ? this.renderExpandedView(true) : window.innerWidth > 728 ? <div>
            <div><table id={this.props.id} className={"desktop-view " + this.props.className}>
                <tbody>
                    <tr className="header">
                        {this.props.rowHeader && <th className="info-column">
                            {LocalizationSingleton.getService().words.Actions}</th>}
                        {cols.map((col) => <th className={col.className}> {col.title(col.id)} </th>)}
                    </tr>
                    {this.props.rows.map((item, index) => <><tr key={index}>
                        {this.props.rowHeader && <td className="info-column">
                            <Icon name={index === this.expanded ? "angle-up" : "angle-down"} source={IconSources.FONTAWESOME}
                                onClick={() => this.onExpandChange(index)} />
                            {this.props.rowHeader(item)}
                        </td>}
                        {cols.map((col) => {
                            return <td className={col.className}>
                                {col.rowRenderer ? col.rowRenderer(item) : item[col.id as keyof T] as any}
                            </td>
                        })}
                    </tr>
                        {this.renderDetails(index)}
                    </>)}
                </tbody></table>
            </div>
        </div>
            : this.renderExpandedView(false, true)
    }
}