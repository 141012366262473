// import { action } from "mobx";
// import { Observer, observer } from "mobx-react";
import React from "react";

import { BaseProps } from "../GeneralMachine";

export class Recall extends React.Component<BaseProps> {
    externalScript?: HTMLScriptElement;
    componentDidMount(): void {
        this.externalScript = document.createElement('script');
        this.externalScript.src = "https://tools.cdc.gov/1M1B";
        this.externalScript.async = true;
        document.head.append(this.externalScript);
    }
    render() {
        return <div data-cdc-widget="FoodRecall" data-instance-name="Food%20Recall%20Widget"
            data-widget-max-width="800px" data-news-feed-type="xml" className="FoodRecall"
            data-news-feed="https%3A%2F%2Fwww2c.cdc.gov%2Fpodcasts%2Fcreaterss.asp%3Fc%3D146"
            data-widget-title="Food%20Recalls" data-widget-min-height="0"
            data-style-path="/foodsafety/foodsafetygov/css/foodsafety-recall-widget.css?v" >
        </div>;
    }
    componentWillUnmount(): void {
        if (this.externalScript) {
            this.externalScript.remove();
            this.externalScript = undefined;
        }
    }
}