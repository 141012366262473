import { action, observable, runInAction } from "mobx";
import { ReactElement, JSXElementConstructor } from "react";
import React from "react";
import { Column } from "../../components/panels/Table";
import { GeneralMachine } from "../GeneralMachine";
import { FdcFood } from "../services/ShelfLifeService";
import { InventoryItem } from "../Utils";
import { NutritionTable } from "./NutritionTable";
import { DialogSingleton } from "../../components/dialog/AppOverlay";
import { LocalizationSingleton } from "../services/localization/LocalizationSingleton";

export enum FiltersEnum {
  NOT_TOXIC = "NOT_TOXIC",
  TOXIC = "TOXIC",
  ALLERGEN = "ALLERGEN",
  UNKNOWN = "UNKNOWN"
}

export type SortBy = 'dataType.keyword' | 'lowercaseDescription.keyword' | 'fdcId' | 'publishedDate';
export type Sort = 'asc' | 'desc';

export class HealthRisksMachine {
  @observable search: string = "";
  @observable page: number = 1;
  @observable busy = false;
  @observable expanded = false;
  @observable allergy = "";
  @observable personalToxicity: Filter[] = observable.array();

  @observable columnsExpanded = false;
  @observable preferencesExpanded = false;
  @observable allergensExpanded = true;
  @observable filtersExpanded = false;

  @observable showingMenu = false;

  @observable sortBy?: SortBy;
  @observable sortOrder?: Sort;
  @observable brandOwner?: string;
  private _sortBy?: SortBy;
  private _sortOrder?: Sort;
  private _brandOwner?: string
  private _search: string = "";
  private _page: number = 0;

  lastSearch: FdcFood[] = [];
  searchPages?: { currentPage?: number, pages?: number[], totalPages?: number };
  searchAutocomplete: string[] = [];
  columns: FdcResultColumn[] = [];
  filters: Filter[] = [];

  static searchDatalist = "shelfLifeSearch";
  static allergiesKey = "allergies";
  readonly cols = [{ id: "shortDescription", header: true },
  { id: "toxicIngredients", titleRow: true },
  { id: "brandName", titleRow: true },
  { id: "description" },
  { id: "brandOwner" },
  { id: "foodCategory" },
  { id: "householdServingFullText" },
  { id: "servingSize" },
  { id: "packageWeight" },
  { id: "tradeChannels" },
  { id: "foodNutrients", hideTitle: true },
  { id: "ingredients" },
  { id: "marketCountry" },
  { id: "fdcId" },
  { id: "gtinUpc" }
  ];

  constructor(public parentMachine: GeneralMachine) {
    let searches = window.localStorage.getItem(HealthRisksMachine.searchDatalist);
    let allergies = window.localStorage.getItem(HealthRisksMachine.allergiesKey);
    this.personalToxicity = allergies ? JSON.parse(allergies) : [];
    this.searchAutocomplete = searches ? JSON.parse(searches) : [];
    this.cols.forEach((col) => {
      this.columns.push(new FdcResultColumn(col.id, col.header, col.titleRow, col.hideTitle));
    });
    for (const filter in FiltersEnum) {
      this.filters.push(new Filter(filter as FiltersEnum, () => LocalizationSingleton.getService().words.FilterMapping(filter as FiltersEnum) || ""));
    }
  }

  get lastSearchParams(): string {
    return this._page + this._search;
  }

  get currentSearchParams(): string {
    return this.page + this.search;
  }

  updateUrl() {
    this.search.length > 0 && window.history.replaceState(null, "", '/healthRisks?q=' + this.search + "&page=" + this.page);
  }

  @action
  searchFDC(isUserAction: boolean, search?: string, page: number = 1): Promise<void> {
    if (this._search !== (search || this.search)) {
      this._search = this.search;
      this._page = this.page;
      this.search = search || this.search;
      this.searchAutocomplete.indexOf(this.search) === -1 && this.searchAutocomplete.push(this.search);
      if (this.searchAutocomplete.length > 5) {
        this.searchAutocomplete = this.searchAutocomplete.slice(1);
      }
      this.updateSearchLocalStorage();
    }
    this.page = page;
    this.updateUrl();
    if (this.currentSearchParams !== this.lastSearchParams || isUserAction) {
      return this.searchFDCAPI();
    }
    return Promise.resolve();
  }

  async searchFDCAPI(): Promise<void> {
    runInAction(() => {
      this.busy = true;
    })
    try {
      let results = await this.parentMachine.services.searchFDC(this.search, this.page,
        true, this.personalToxicity.filter((allergen) => allergen.included).map((value => value.id)), this._sortBy, this._sortOrder, this._brandOwner);
      this.lastSearch = results.foods || [];
      this.searchPages = {
        currentPage: results.currentPage, totalPages: results.totalPages,
        pages: results.pageList
      };
    } catch (error) {
      DialogSingleton.getManager().openDialog({ title: LocalizationSingleton.getService().words.Error, key: this.search, content: (error as any).message })
    }

    runInAction(() => {
      this.busy = false;
    })
  }

  savePreferences(): void {
    this._sortBy = this.sortBy;
    this._brandOwner = this.brandOwner;
    this._sortOrder = this.sortOrder;
  }

  @action
  cancelPreferences(): void {
    this.sortBy = this._sortBy;
    this.brandOwner = this._brandOwner;
    this.sortOrder = this._sortOrder;
  }

  getPageRange(currentPage: number, lastPage: number): number[] {
    let pages = [];
    let fromLast = lastPage - currentPage;
    if (currentPage < 5) {
      for (let i = 1; i <= Math.min(lastPage, 9); i++) {
        pages.push(i);
      }
      return pages;
    } else if (fromLast < 5) {
      for (let i = 9; i >= 1; i--) {
        pages.push(lastPage - i);
      }
      return pages;
    } else {
      for (let i = 1; i <= 9; i++) {
        pages.push(currentPage - 4 + i);
      }
      return pages;
    }
  }

  updateSearchLocalStorage(): void {
    window.localStorage.setItem(HealthRisksMachine.searchDatalist, JSON.stringify(this.searchAutocomplete))
  }

  updateAllergiesLocalStorage(): void {
    window.localStorage.setItem(HealthRisksMachine.allergiesKey, JSON.stringify(this.personalToxicity))
  }

  addItemToInventory(food: FdcFood): void {
    let prod = !food.brandName || food.description.indexOf(food.brandName) > -1 ? food.description : (food.brandName + " " || "") + food.description;
    let inventoryItem = new InventoryItem(prod, undefined, food.foodCategory, "1", food.gtinUpc);
    inventoryItem.toxicIngredients = food.toxicIngredients;
    this.parentMachine.inventoryMx.addToInventory([inventoryItem]);
  }
}

export class FdcResultColumn implements Column<FdcFood> {
  rowRenderer?: ((item: FdcFood) => ReactElement<any, string | JSXElementConstructor<any>>) | undefined;
  constructor(public id: string, public header?: boolean, public titleRow?: boolean, public hideTitle?: boolean) {
    if (id === "foodNutrients") {
      this.rowRenderer = (item) => <NutritionTable nutrients={item.foodNutrients || []} /> || null;
    }
    if (id === "shortDescription") {
      this.rowRenderer = (item) => <div>{item.shortDescription || item.description}</div>;
    }
    if (id === "toxicIngredients") {
      this.rowRenderer = (item) => <div>{item.toxicIngredients ? item.toxicIngredients.length > 0
        ? item.toxicIngredients.map((item) => item.chemical + " (" + item.matched.trim() + ")" + LocalizationSingleton.getService().words.WithToxicity + item.toxicity + ". ")
        : LocalizationSingleton.getService().words.NotToxic : LocalizationSingleton.getService().words.UknownToxicity}</div>
    }
    if (id === "servingSize") {
      this.rowRenderer = (item) => <div>{item.servingSize + " " + item.servingSizeUnit}</div>
    }
  }

  title(id: string): string {
    return LocalizationSingleton.getService().words.ColumnTitleMap.get(id) || "";
  }
}

export class Filter {
  @observable included = false;
  title?: string;
  constructor(public id: string | FiltersEnum, title: () => string, included?: boolean) {
    this.title = title();
    this.included = Boolean(included);
  }
}
